<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <TopDealForm></TopDealForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
            <div class="vx-card p-6 mb-6">
              <TopDealImage></TopDealImage>
            </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import TopDealForm from './common/TopDealForm';
import TopDealImage from './common/TopDealImage';
import {TOP_DEAL} from '../../../constant/entity-identifier';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

import {storeImage, updateImage} from '../../../services/image';

export default {
  components: {
    vSelect,
    TopDealForm,
    TopDealImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        form: 'topdeals/getFormObj',
        images: 'topdeals/getImages',
    }),
    getTopDealId() {
        return this.$route.params.topdealId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();
     this.openLoading();
     this.fetchAndSetTopDealById(this.getTopDealId).then(() => {
       this.closeLoading();
     }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateTopDealAction: 'topdeals/updateTopDealAction',
      fetchAndSetTopDealById: 'topdeals/fetchAndSetTopDealById',
      clearForm: 'topdeals/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),
    prepareForm(data, type, method) {
      let formData = new FormData();
      formData.append("_method", method);
      formData.append("entity_id", data.id);
      formData.append("entity", type);
      formData.append("images[]", this.images[0]);
      formData.append("alt[]", 'image');
      return formData;
    },
    submitForm() {
     this.openLoading();
      this.updateTopDealAction({
        topdealId: this.getTopDealId,
        data: this.form
      }).then( async response => { 
        if(response.status === 202) {
            if(this.images.length > 0) {
                if(response.data.data.image){
                    await updateImage(response.data.data.image[0].id, this.prepareForm(response.data.data, TOP_DEAL, 'POST'));
                }else{
                    await storeImage(this.prepareForm(response.data.data, TOP_DEAL, 'POST'));
                }
                this.closeLoading();
            }else{ this.closeLoading();
            }  
        this.$vs.notify({ 
            text: 'Top Deal updated successfully.',
            title: 'Top Deal Updated',
            color: 'success',
            position: 'top-right'
        });
        this.$router.push('/topdeals');}
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
